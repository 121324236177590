<template>
  <div class="contract-modal">
    <sweet-modal ref="modal" title="계약조건" overlay-theme="dark">
      <div class="contract-modal-content sub2" v-if="visible">
        <div>
          <div class="body3-bold main flex-align" style="gap:8px;margin-bottom:4px"><svg-icon :width="20" :height="20" icon="u_money-bill" color="#ff6600" />총 계약금</div>
          <div class="body3-bold main">{{ currencyNum(value.price) }}<span class="body3">원</span></div>
          <div v-if="!value.vat" class="body4 sub3">*부가세 미포함</div>
        </div>
        <div>
          <div class="body3-bold main flex-align" style="gap:8px;margin-bottom:4px"><svg-icon :width="20" :height="20" icon="u_calender" color="#ff6600" />계약기간</div>
          {{ datesFormat(value.start_date, 'date_3') }} ~ {{ datesFormat(value.end_date, 'date_3') }}
        </div>
        <div>
          <div class="body3-bold main flex-align" style="gap:8px;margin-bottom:4px"><svg-icon :width="20" :height="20" icon="u_money-insert" color="#ff6600" />선금/중도금/잔금</div>
          <ul>
            <li>선금 : {{ datesFormat(value.deposit_date, 'date_3') }} ({{ currencyNum(value.deposit) }}원)</li>
            <li v-if="value.intermediate">중도금 : {{ datesFormat(value.intermediate_date, 'date_3') }} ({{ currencyNum(value.intermediate) }}원)</li>
            <li>잔금 : {{ datesFormat(value.balance_date, 'date_3') }} ({{ currencyNum(value.balance) }}원)</li>
          </ul>
        </div>
        <div>
          <div class="body3-bold main flex-align" style="gap:8px;margin-bottom:4px"><svg-icon :width="20" :height="20" icon="u_edit" color="#ff6600" />계약내용</div>
          <div class="body3 sub2" style="white-space: pre-wrap;">{{ value.content }}</div>
        </div>
        <div v-if="value.file">
          <div class="body3-bold main flex-align" style="gap:8px;margin-bottom:4px"><svg-icon :width="20" :height="20" icon="u_file-alt" color="#ff6600" />계약서 파일</div>
          <a :href="value.file" target="_blank">
            <div class="file">
              <svg-icon :width="20" :height="20" icon="u_file" color="#bdbdbd" />
              <div class="body3 sub2">{{ value.file.split('/').pop() }}</div>
            </div>
          </a>
        </div>
        <div class="flex-align" style="margin-top:40px;gap: 8px;" v-if="!$route.path.includes('partner') &&value.status === 0">
          <button class="button is-gray4" @click="clickContract(101)">진행하지 않음</button>
          <button class="button is-primary" @click="clickContract(1)">계약하기</button>
        </div>
      </div>
    </sweet-modal>
    <div class="result-modal">
      <sweet-modal ref="done" overlay-theme="dark" hide-close-button>
        <div class="result-content">
          <div class="body2-bold main">️계약 진행이 확정되었습니다.</div>
          <div class="body4" style="margin:8px 0 40px 0;white-space: pre-wrap;">
            파트너가 계약서를 작성할 때까지 기다려 주세요!<br>
            계약서 작성이 완료되면 전달 드리겠습니다<br><br>
            ⏩ 이후 프로세스<br>
            1. 계약서 서명<br>
            메일로 보내드리는 표준 용역 계약서에 서명을 해주세요.<br><br>
            2. 계약금 입금<br>
            입금을 완료하면 계약이 완료됩니다.<br><br>
            3. 프로젝트 킥오프<br>
            프로젝트 진행에 대한 세부 프로세스를 확인하려면 아래 프로세스 안내를 눌러주세요
          </div>
        </div>
        <div class="flex" style="gap: 8px;flex-direction: column;">
          <button class="button is-gray4" @click="clickProcessGuide">프로세스 안내</button>
          <button class="button is-gray" @click="clickContractList">계약 내역</button>
        </div>
      </sweet-modal>
      <sweet-modal ref="refuse" overlay-theme="dark" hide-close-button>
        <div class="result-content">
          <div class="body2-bold main">계약이 성사되지 않았습니다.</div>
          <div class="body4" style="margin:8px 0 40px 0;white-space: pre-wrap;">
            아쉽지만 이번에는 계약이 성사되지 않았습니다.<br>
            더 마음에 드는 파트너를 찾을 수 있도록 계속 도와드릴게요!<br><br>
            내가 꿈꾸던 플랫폼 런칭, 런치팩에서 시작하세요.
          </div>
        </div>
        <button class="button is-gray"  style="width:100%"
          @click="$refs.refuse.close()">확인</button>
      </sweet-modal>
    </div>
  </div>
</template>
<script>
import { SweetModal } from "sweet-modal-vue";
import UserAPIMixin from "../../mixins/UserAPIMixin";
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import SvgIcon from './SvgIcon.vue';
Vue.use(VueClipboard);
export default {
  name: "ContractModal",
  components: {
    SweetModal,
    SvgIcon,
  },
  mixins: [UserAPIMixin],
  props: {},
  data() {
    return {
      id: null,
      visible: false,
      value: {
        price: 0,   // 총 계약금
        vat: false, // 부가세 포함 여부
        start_date: null, // 계약 시작일
        end_date: null, // 계약 종료일
        deposit: 0, // 선금
        intermediate: 0, // 중도금
        balance: 0, // 잔금
        deposit_date: null, // 선금 납입일
        intermediate_date: null, // 중도금 납입일
        balance_date: null, // 잔금 납입일
        content: "", // 계약 내용
        file: null, // 계약서 파일
      },
    };
  },
  computed: {
  },
  watch: {},
  mounted() {},
  methods: {
    init() {
      this.value = {
        price: 0,   // 총 계약금
        vat: false, // 부가세 포함 여부
        start_date: null, // 계약 시작일
        end_date: null, // 계약 종료일
        deposit: 0, // 선금
        intermediate: 0, // 중도금
        balance: 0, // 잔금
        deposit_date: null, // 선금 납입일
        intermediate_date: null, // 중도금 납입일
        balance_date: null, // 잔금 납입일
        content: "", // 계약 내용
        file: null, // 계약서 파일
      };
    },
    open(id) {
      this.visible = false;
      this.id = id;
      this.init();
      if(id) {
        this.getData();
      } else {
        this.visible = true;
      }
      this.$refs.modal.open();
    },
    clickProcessGuide() {
      this.$refs.done.close();
      setTimeout(() => {
        window.open('https://launchpack.notion.site/107993d155d680cba808e55044f29bd2', '_blank');
      }, 500);
    },
    clickContract(status) {
      this.setLoading('계약 처리 중');
      this.request.user.patch(`launchpack/contract/${this.id}`, {status: status}).then(() => {
        this.clearLoading();
        this.value.status = status;
        this.$refs.modal.close();
        this.$emit('done');
        setTimeout(() => {
          if(status === 1) {
            this.$refs.done.open();
          } else {
            this.$refs.refuse.open();
          }
        }, 500);
      }).catch(() => {
        this.clearLoading();
        this.toast('계약 처리에 실패했습니다.');
      });
    },
    getData() {
      this.request.user.get(`launchpack/contract/${this.id}`).then(res => {
        this.value = res.data;
        this.visible = true;
      });
    },
    clickContractList() {
      this.$refs.done.close();
      setTimeout(() => {
        this.$router.push('/mypage/my_contract');
      }, 500);
    }
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.contract-modal
  button
    flex 1
    height 52px
    line-height 52px
    font-size 16px
  .button.is-gray4
    background $gray3
    border-color $gray3
  .result-modal
    .button
      font-size 14px
      height 44px
      line-height 44px

.contract-modal-content
  display flex
  flex-direction column
  gap 24px
  .label
    font-size 15px
    font-weight 500
    margin-bottom 8px
  .button
    width 100%
    height 52px
    font-size 16px
    font-weight 700
    line-height 24px
  .required
    color red
  .input-label
    font-size 15px
    font-weight 400
    margin-bottom 4px
    color $sub2
ul
  list-style disc
  padding-left 16px
  li
    line-height 1.7
.result-content
  text-align left

.file
  display flex
  align-items center
  gap 8px
  border 1px solid #ddd
  padding 12px
  border-radius 8px
</style>

<style lang="stylus">
.contract-modal
  .sweet-modal .sweet-content
    display block
  .sweet-modal .sweet-title
    border-bottom 0
    padding 16px
    height fit-content
  .sweet-modal .sweet-title > h2
    font-size 18px
    font-weight 700
    line-height 24px
  .sweet-modal .sweet-box-actions
    top 4px
  .sweet-modal .sweet-buttons, .sweet-modal .sweet-content
    padding 16px
@media (min-width: 601px)
  .contract-modal
    .sweet-modal
      width 430px
      max-height 90vh
@media (max-width: 600px)
  .contract-modal
    .sweet-modal
      width 100%
      border-radius 0 !important
    .sweet-modal .sweet-content .sweet-content-content
      padding 0 16px
      
</style>
